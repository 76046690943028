
import { defineComponent, onMounted, reactive } from "vue";
import ApiService from "@/core/services/ApiService";
import { ErrorMessage, Field, Form } from "vee-validate";
import store from "@/store";
import { Mutations, Actions } from "@/store/enums/StoreEnums";
import { useToast } from "vue-toastification";
import { useRouter, useRoute } from "vue-router";
import pdfMake from "pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import htmlToPdfmake from "html-to-pdfmake";
import * as jsPDF from "jspdf";
import moment from "moment";
import { Modal } from "bootstrap";

import Multiselect from "@vueform/multiselect";
import Loading from "vue-loading-overlay";

import Swal from "sweetalert2/dist/sweetalert2.min.js";
import "vue-loading-overlay/dist/vue-loading.css";


interface Book {
  detail: any;
  invoice: any;
  bankName: string;
  metodeName: string;
  sumberdana: string;
  nomorRekening: string;
  addInvoices: any;
  preview: any;
}

interface Send {
  data: any;
}


export default defineComponent({
  name: "kt-widget-11",
  components: {
    Field,
    Loading,

    Multiselect,
    pdfMake,
  },
  props: {
    widgetClasses: String,
  },

  data() {
    return {
      // loading overlay setting
      isLoading: false,
      fullPage: true,
      isColor: "#007BFF",
      isBackgroundColor: "#A3A3A3",
      isOpacity: 0.5,

      imageFotoBank: "",
      // end loading overlay setting

      Data: {
        "createTime": "",
        "creatorId": "",
        "updateTime": "",
        "editorId": "",
        "isDelete": false,
        "deleteBy": null,
        "deleteTime": null,
        "schoolId": "",
        "paymentId": "",
        "schoolName": "",
        "address": "",
        "phone": "",
        "email": "",
        "specialCase": false,
        "historyCase": [],
        "note": null,
        "title": "",
        "item": "",
        "invoiceDate": "",
        "invoiceYear": "",
        "sourceNumber": "",
        "number": "",
        "vaNumber": "",
        "vaName": "",
        "vaBank": "",
        "expiredDate": "",
        "detail": [
          {
            "id": "",
            "item": "",
            "category": "",
            "name": "",
            "price": 0,
            "discount": 0,
            "tax": 0,
            "qty": 0,
            "amount": 0
          }
        ],
        "paymentStatus": "UNPAID",
        "paymentMethod": "MANUAL",
        "paidAmount": 0,
        "amount": 0,
        "discount": 0,
        "discountDetails": [],
        "tax": 0,
        "shipping": 0,
        "totalAmount": 0,
        "wordCurrency": "",
        "historyPayment": [],
        "tags": [],
        "callbacks": [
          {
            "category": "",
            "refId": "",
            "amount": 0,
            "coaCode": ""
          }
        ],
        "_id": ""
      },
      detail: [],

      book: {
        detail: [],
        invoice: [],
        bankName: "",
        coaCode: "",
        sumberdana: "",
        sumberdanaa: "",
        coa: "Topup Manual",
        saldo_sumberdana: 0,
        metodeName: "Otomatis",
        nomorRekening: "",
        addInvoices: [],
        preview: [],
      } as Book,

      wordCurrency: "",
      totalInvoice: "",
      amount: 0,
      discount: 0,
      tax: 0,
      shipping: 0,
      balanceCoa: 0,
      totalAmount: 0,
      totalPaid: "",
      totalUnPaid: "",
      listbankManual: [
        {
          initial: "BNI",
          no: "7000040408",
          name: "PT Teknologi Kartu Indonesia",
          icon: "/media/bank/BNI.svg",
        },
        {
          initial: "Jatim Syariah",
          no: "6201000466",
          name: "PT Teknologi Kartu Indonesia",
          icon: "/media/bank/bank_jatim_syariah.png",
        },
        {
          initial: "BSI",
          no: "8882609178",
          name: "PT Teknologi Kartu Indonesia",
          icon: "/media/bank/bsi.svg",
        },
        {
          initial: "NTBS",
          no: "0010100163276",
          name: "PT Teknologi Kartu Indonesia",
          icon: "/media/bank/NTBS.svg",
        },
      ],
      bankManual: "",
      minJtp: "",
      sumberdana: "",
      schoolId: "",
      schoolSelected: false,
      invoiceDate: "",
      checkedItem: [],
      titip: [],
      listBank: [{
        "createTime": "",
        "creatorId": "",
        "updateTime": "",
        "editorId": "",
        "isDelete": false,
        "deleteBy": "",
        "deleteTime": "",
        "bankName": "",
        "bankCode": "",
        "bankType": "",
        "bankAccountNumber": "",
        "bankAccountName": "",
        "tutorial": "",
        "status": true,
        "description": "",
        "_id": ""
      }],
      listBankOtomatis: [{
        "createTime": "",
        "creatorId": "",
        "updateTime": "",
        "editorId": "",
        "isDelete": false,
        "deleteBy": "",
        "deleteTime": "",
        "bankName": "",
        "bankCode": "",
        "bankType": "",
        "bankAccountNumber": "",
        "bankAccountName": "",
        "tutorial": "",
        "status": true,
        "description": "",
        "_id": ""
      }],
      listBankManual: [{
        "createTime": "",
        "creatorId": "",
        "updateTime": "",
        "editorId": "",
        "isDelete": false,
        "deleteBy": "",
        "deleteTime": "",
        "bankName": "",
        "bankCode": "",
        "bankType": "",
        "bankAccountNumber": "",
        "bankAccountName": "",
        "tutorial": "",
        "status": true,
        "description": "",
        "_id": ""
      }],
      catatan:
        "Status pembayaran akan berubah secara otomatis setelah invoice terbayar",
      konfirm: true,
      paymentStatus: "UNPAID",
      viewInvoices: [],
      date: "",
      Idinvoice: "",
      InvoicesById: [],
      tutorial: "",
      btnBayar: true,
      statusBayar: true,
      doSubmit: true,
      error1: {
        selectBank1: "",
      },
      error2: {
        selectBank2: "",
      },
      note: "-",
      title: "",
      number: "",
      itemType: "",
      invoiceYear: "",
      listcoa: [],
      targetKonfirmasi: {
        id: "",
        namaPengirim: "",
        rekTujuan: "",
        persetujuanFinance: "",
        rekPengirim: "",
        tanggal: "",
        note: "",
        paymentId: "",
        noRef: "",
        penanggungJawab: "",
        namaRektujuan: "",
        paidAmount: 0,
        imageBuktiTF: "",
        imageBuktiTFRslt: "",
      },
      errorBayarManual: {
        noRef: "",
        penanggungJawab: "",
        imageBuktiTF: "",
        namaPengirim: "",
        tanggal: "",
      },

      modalpaymentByID: "",
      tipeBayarManualSingle: false,
      buttonBayarManualSingle: false,
      resultBayarManual: false,
      successBayarManual: false,

      showtutorial: false,

      statusmodalbayarsinglepaid: false,
      statusmodalbayarsingleva: false,
      previewPembayaranSingle: false,

      showVaName: "",
      showVaBank: "",
      showVaNumber: "",
      showExpVaNumber: "",
      showFormCoa: false,
      paymentStatusInit: false,
      schoolid2: "",
      role: "",
      name_user: "",
      id_user: "",


      alertChange: "Metode Pembayaran Berhasil Dipilih",
      sumber: "",
      aksesBayarGabung: false,
      aksesBayar: false,
      aksesDelete: false,
      deleteByStatus: false,
      dataPayment: {
        paymentId: "",
        paymentMethod: "",
        paymentStatus: "",
        vaName: "",
        vaBank: "",
        showExpVaNumber: "",
        vaNumber: "",
        item: "",
      },
      checkDataPayment: {
        data: {
          callbacks: [{ coaCode: "" }],
          vaBank: ""
        }
      },
    };

  },

  beforeMount() {

    this.getIdInvoices();

  },

  methods: {
    getIdInvoices() {
      this.isLoading = true;

      const toast = useToast();
      const $route = useRoute();
      this.getListBank("AUTOMATIC", "")
      this.getListBank("MANUAL", "")


      ApiService.getWithoutSlug(
        "crmv2/main_invoice/invoice/psp/invoice/" + this.$route.params._id
      )
        .then((data) => {
          this.Data = data.data;

          var phone = this.Data.phone,
            replaced = phone.slice(0, 2) + phone.slice(2).replace(/.(?=...)/g, 'X');
          var address = this.Data.address,
            ganti = address.slice(0, 4) + address.slice(2).replace(/.(?=...)/g, 'X');
          this.Data.phone = replaced
          this.Data.address = ganti


          switch (data.data.vaBank) {
            case 'NTB SYARIAH':
              this.imageFotoBank = "/media/bank/bank_ntbs.png";
              break;
            case 'BNI':
              this.imageFotoBank = "/media/bank/bank_bni.png";
              break;
            case 'BRI':
              this.imageFotoBank = "/media/bank/bank_bri.png";
              break;
            case 'BSI-SBI':
              this.imageFotoBank = "/media/bank/bank_bsi.png";
              break;
            case 'BANKJATIM':
              this.imageFotoBank = "/media/bank/bank_jatim_syariah.png";
              break;
            case 'MANDIRI':
              this.imageFotoBank = "/media/bank/bank_mandiri.png";
              break;
            case 'PERMATA':
              this.imageFotoBank = "/media/bank/bank_permata.png";
          }

          this.detail = data.data.detail;
          this.getTutorial(data.data.vaBank)
          this.isLoading = false;
        })
        .catch((_e) => {
          this.isLoading = false;
          // toast.error(e.res.data.detail)
        });
    },
    getListBank(e, va) {
      ApiService.getWithoutSlug("crmv2/main_bank/bank/payment/type/" + e).then((resp) => {


        if (e == "AUTOMATIC") {
          this.listBankOtomatis = resp.data
        } else {
          this.listBankManual = resp.data
        }
        this.listBank = [...this.listBankOtomatis, ...this.listBankManual]
        console.log(this.listBank, 'lkjhh');
      }).catch((err) => {
        console.log(err);
        this.listBank = []

      });
    },

    returnBankName(e) {
      var a = this.listBank.filter(item => item.bankName === e)
      var c = ""
      this.listBank.forEach(element => {
        if (element.bankName === e) {
          c = element.bankAccountNumber
        }
      });
      // console.log(a[0].bankAccountNumber, "sejjjd");
      return c

    },
    returnAcontName(e) {
      var a = this.listBank.filter(item => item.bankName === e)
      // console.log(a[0].bankAccountNumber, "sejjjd");
      var c = ""
      this.listBank.forEach(element => {
        if (element.bankName === e) {
          c = element.bankAccountName
        }
      });
      // console.log(a[0].bankAccountNumber, "sejjjd");
      return c

    },
    getTutorial(sd) {
      var a = ""
      console.log(this.listBank, "ssee");

      this.listBank.forEach(element => {
        if (this.book.metodeName == "Manual") {

          if (element.bankName == sd) {

            a = element.tutorial
          }
        } else {
          if (element.bankCode == sd) {
            a = element.tutorial
          }
        }
      })
      if (a == '' || a == null) {
        this.showtutorial = false
      } else {
        this.showtutorial = true
      }
      this.tutorial = a

    },
    getDataCache() {
      this.isLoading = true;

      const array = JSON.parse(localStorage.getItem("listDetailInv")!);

      this.Data = array;
      this.detail = array.detail;
      this.isLoading = false;
    },

    printpreview() {
      const modal = new Modal(document.getElementById("kt_modal_print"));
      modal.show();
    },

    printpdf() {
      var printContents = document.getElementById("capturepdf")!.innerHTML;

      document.body.innerHTML = printContents;
      window.print();
      location.reload();
    },


    formatPrice(value) {
      let val = (value / 1).toFixed(2).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },

    formatDate(value) {
      return moment(value).format("L");
    },
    changeMetode(prop) {
      console.log(prop.target.value);
      var metode = prop.target.value == "Manual" ? "MANUAL" : "AUTOMATIC"
      this.getListBank(metode, "")
      // this.book.metodeName = prop
    },
    bantuanModal() {
      var modal = new Modal(document.getElementById("kt_modal_4"));
      // this.bayarPerInvoiceId(b);
      modal.show();
    },
    cekStatus(e, data) {
      // CLEAR DATA

      this.dataPayment.paymentId = data.paymentId || "";
      this.dataPayment.paymentMethod = data.paymentMethod || "";
      this.dataPayment.paymentStatus = data.paymentStatus || "";
      this.dataPayment.vaName = data.vaName || "";
      this.dataPayment.vaBank = data.vaBank || "";
      this.dataPayment.vaNumber = data.vaNumber || "";
      this.dataPayment.item = data.item || "";

      this.totalAmount = data.totalAmount || "";
      this.schoolid2 = data.schoolId || "";
      this.sumber = data.item;

      if (data.paymentStatus == "PAID") {
        this.paymentStatusInit = true;
      }
      var urlrole = "crmv2/main_invoice/invoice/psp/invoice";


      ApiService.getWithoutSlug(`${urlrole}/wait/${e}`)
        .then((resp) => {
          if (resp.data == true) {
            var modal = new Modal(document.getElementById("kt_modal_menunggu"));
            modal.show();
          } else {
            if (
              this.dataPayment.paymentStatus == null ||
              this.dataPayment.paymentStatus == ""
            ) {
              var modal = new Modal(document.getElementById("kt_modal_2"));
              modal.show();
            } else {
              ApiService.getWithoutSlug(`${urlrole}/payment_data/${e}`).then(
                // ApiService.getWithoutSlug(`${urlrole2}${e}`).then(
                (res2) => {
                  this.checkDataPayment = res2.data;
                  this.getcombocoa(data.schoolId);

                  if (res2.data.paymentMethod == "MANUAL") {
                    this.book.metodeName = "Manual";
                    // console.log(res2.data.data);
                    this.getTutorial(data.vaBank)

                    var init = "";
                    // this.bankManual = res2.data.data.vaBank;
                    // this.book.bankName = res2.data.data.vaBank;
                    // this.getListBank(res2.data.paymentMethod, res2.data.data.vaBank)
                    this.listbankManual.forEach((element) => {
                      if (element.initial == res2.data.data.vaBank) {
                        init = element.name;
                        this.targetKonfirmasi.rekTujuan = element.no;
                      }
                    });
                    this.targetKonfirmasi.namaRektujuan = init;
                    // this.targetKonfirmasi.rekTujuan = res2.data.data.vaNumber;
                    this.targetKonfirmasi.paidAmount =
                      res2.data.data.totalAmount;
                    this.targetKonfirmasi.id = res2.data.data.invoiceId;
                    this.Idinvoice = res2.data.data.invoiceId;
                    this.dataPayment.item = data.item;
                    var modal = new Modal(
                      document.getElementById("kt_modal_2")
                    );
                    modal.show();
                    // this.paymentByID(met);
                  } else {
                    this.targetKonfirmasi.namaRektujuan = res2.data.data.vaName;
                    //  this.bankManual = res2.data.data.vaBank;
                    this.book.bankName = res2.data.data.vaBank;
                    this.targetKonfirmasi.rekTujuan = res2.data.data.vaNumber;
                    this.Idinvoice = res2.data.data.invoiceId;

                    this.getTutorial(data.vaBank)
                    this.getListBank("AUTOMATIC", data.vaBank)
                    var modal = new Modal(
                      document.getElementById("kt_modal_2")
                    );

                    this.book.metodeName = "Otomatis";
                    console.log(data.vaBank, "aksksk");
                    this.bayarPerInvoiceId(data);
                    modal.show();
                  }
                }
              );
            }
          }
        })
        .catch((e) => { });
    },

    submitBayarManual() {
      // const modal = new Modal(document.getElementById("kt_modal_konfirm_manual"));
      // modal.show();
      Swal.fire({
        title:
          "Apakah Anda Yakin akan menggabungkan invoices dan membayar manual?",
        type: "warning",
        showCancelButton: true,
        confirmButtonClass: "btn btn-success btn-fill",
        cancelButtonClass: "btn btn-danger btn-fill",
        confirmButtonText: "Ok !",
        cancelButtonText: "Kembali",
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.konfirmasiManual();
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          // Swal.fire("Cancelled", "Bayar Invoice terlebih dahulu", "error");

          this.book.bankName = "";
          this.error1.selectBank1 = "";
          // this.addPreview();
        }
      });
    },

    konfirmasi() {
      this.isLoading = true;

      // this.konfirm = false;

      var today = new Date();
      var date =
        today.getFullYear() +
        "-" +
        (today.getMonth() + 1) +
        "-" +
        today.getDate();
      var m = moment().format("DD/MM/YYYY");

      this.book.addInvoices = {
        invoiceDate: date,
        detail: [],
      };
      var url =
        "crmv2/main_invoice/invoice/psp/invoice/pay?bankName=" +
        this.book.bankName +
        "&invoiceId=" +
        this.Idinvoice;

      if (this.role !== "SUPER_ADMIN") {
        url = "crmv2/main_invoice/invoice/";
      }

      var metod = this.book.metodeName == "Otomatis" ? "AUTOMATIC" : "MANUAL";
      this.book.addInvoices["detail"] = this.book.invoice.detail;
      ApiService.postWithData(
        "crmv2/main_invoice/invoice/psp/invoice?schoolId=" +
        this.schoolId +
        "&title=Tagihan" +
        "&itemType=Package",
        this.book.addInvoices
      )
        .then((res) => {
          this.Idinvoice = res.data._id;

          url =
            "crmv2/main_invoice/invoice/psp/invoice/pay?bankName=" +
            this.book.bankName +
            "&invoiceId=" +
            this.Idinvoice +
            "&paymentMethod=" +
            metod;

          ApiService.postWithoutSlug(url).then((res3) => {
            this.viewInvoices = res3.data;
            localStorage.setItem("listDetailInv", JSON.stringify(res3.data));

            this.$router.push("/sekolah/view-detail-invoices/multi-invoice");

            this.isLoading = false;
          });
        })
        .catch((e) => {
          this.isLoading = false;
        });
    },

    changebankManual(e) {
      this.listBankManual.forEach((element, idx) => {
        if (element.bankName === e.target.value) {
          this.targetKonfirmasi.rekTujuan = element.bankAccountNumber;
          this.targetKonfirmasi.namaRektujuan = element.bankAccountName
          this.book.bankName = element.bankName;
        }
      });
      console.log(this.targetKonfirmasi.rekTujuan, "fdghfh")

      this.getTutorial(e.target.value)
    },

    konfirmasiManual() {
      this.isLoading = true;
      const toast = useToast();

      // this.konfirm = false;

      var today = new Date();
      var date =
        today.getFullYear() +
        "-" +
        (today.getMonth() + 1) +
        "-" +
        today.getDate();
      var m = moment().format("DD/MM/YYYY");

      this.book.addInvoices = {
        invoiceDate: date,
        detail: [],
      };
      var uu = "schoolId=" + this.schoolId;
      this.role == "SUPER_ADMIN" ? (uu = "schoolId=" + this.schoolId) : "";
      var url = "crmv2/main_invoice/invoice/psp/invoice";
      var metod = this.book.metodeName == "Otomatis" ? "AUTOMATIC" : "MANUAL";
      this.book.addInvoices["detail"] = this.book.invoice.detail;
      ApiService.postWithData(
        "crmv2/main_invoice/invoice/psp/invoice?schoolId=" +
        this.schoolId +
        "&title=Tagihan" +
        "&itemType=Package",
        this.book.addInvoices
      )
        .then((res) => {
          this.Idinvoice = res.data._id;
          this.targetKonfirmasi.id = res.data._id;

          ApiService.postWithoutSlug(
            url +
            "/pay?bankName=" +
            this.book.bankName +
            "&invoiceId=" +
            this.Idinvoice +
            "&paymentMethod=" +
            metod
          ).then((res3) => {
            this.showVaName = res3.data.vaName;
            this.showVaBank = res3.data.vaBank;
            this.targetKonfirmasi.namaRektujuan = res3.data.vaBank;
            this.targetKonfirmasi.rekTujuan = res3.data.vaNumber;
            this.targetKonfirmasi.paidAmount = res3.data.totalAmount;
            this.targetKonfirmasi.persetujuanFinance = res3.data.paymentStatus;
            this.targetKonfirmasi.paymentId = res3.data.paymentId;
            this.showVaNumber = res3.data.vaNumber;
            this.showExpVaNumber = res3.data.expiredDate;
            this.viewInvoices = res3.data;
            localStorage.setItem("listDetailInv", JSON.stringify(res3.data));
            this.targetKonfirmasi.paymentId = res3.data.paymentId;

            this.tipeBayarManualSingle = false;
            this.buttonBayarManualSingle = false;

            this.errorBayarManual.noRef = "";
            this.errorBayarManual.penanggungJawab = "";

            // this.$router.push("/superadmin/view-detail-invoices/multi-invoice");
            toast.success("invoice berhasil di gabungkan");

            this.resultBayarManual = false;
            this.targetKonfirmasi.noRef = "";
            this.targetKonfirmasi.penanggungJawab = "";

            const modal = new Modal(
              document.getElementById("kt_modal_konfirm_manual")
            );
            modal.show();

            this.isLoading = false;
          });
        })
        .catch((e) => {
          this.isLoading = false;
        });
    },
    saveModalFormKonfirmasi() {
      const toast = useToast();
      if (
        this.targetKonfirmasi.noRef === "" ||
        this.targetKonfirmasi.namaPengirim === "" ||
        this.targetKonfirmasi.penanggungJawab === "" ||
        this.targetKonfirmasi.imageBuktiTF === "" ||
        this.targetKonfirmasi.tanggal === "" ||
        this.targetKonfirmasi.paidAmount === 0
      ) {
        if (this.targetKonfirmasi.noRef === "") {
          this.errorBayarManual.noRef = "Nomor Referensi Wajib Diisi";
        }
        if (this.targetKonfirmasi.namaPengirim === "") {
          this.errorBayarManual.namaPengirim =
            "Nama Rekening Pengirim Wajib Diisi";
        }
        if (this.targetKonfirmasi.penanggungJawab === "") {
          this.errorBayarManual.penanggungJawab =
            "Penanggung Jawab Wajib Diisi";
        }
        if (this.targetKonfirmasi.tanggal === "") {
          this.errorBayarManual.tanggal = "Tanggal Pembayaran Wajib Diisi";
        }
        if (this.targetKonfirmasi.imageBuktiTF === "") {
          this.errorBayarManual.imageBuktiTF = "File Tidak Boleh Kosong";
          toast.error("Form belum lengkap");
        }
        if (this.targetKonfirmasi.paidAmount === 0) {
          toast.error(
            "Untuk melakukan pembayaran nominal tagihan Tidak Boleh 0"
          );
          return false;
        }
      } else {
        this.isLoading = true;
        // const modal = new Modal(document.getElementById("kt_modal_konfirm_manual"));
        // modal.hide();
        // var modal2 = new Modal(document.getElementById("kt_modal_konfirm_manual_fix"));
        // modal2.show();

        let formData = new FormData();
        formData.append("file", this.targetKonfirmasi.imageBuktiTF);
        let isi = {
          paymentId: this.targetKonfirmasi.paymentId,
          note: this.targetKonfirmasi.note,
          paymentDate: moment(this.targetKonfirmasi.tanggal).format(
            "YYYY-MM-DD"
          ),
          refNumber: this.targetKonfirmasi.noRef,
          paidAmount: this.targetKonfirmasi.paidAmount,
          bankNumbSender: "None",
          bankNameSender: this.targetKonfirmasi.namaPengirim,
          bankNumbTo: this.targetKonfirmasi.rekTujuan,
          bankNameTo: this.targetKonfirmasi.namaRektujuan,
          pic: this.targetKonfirmasi.penanggungJawab,
          imageReceipt: [""],
          status: "WAIT",
        };

        let datanew = {};
        var url = "crmv2/main_invoice/invoice/psp/";

        ApiService.postWithData(
          "crmv2/main_image/image/upload/" + this.targetKonfirmasi.paymentId,
          formData
        )
          .then((res) => {
            // cekSuccess = true;
            // toast.success("Berhasil Upload Foto Siswa");
            isi.imageReceipt = [res.data.imgUrl];
            let conjson = JSON.stringify(isi);
            // datanew =JSON.parse(isi)

            // setTimeout(() => this.getData(this.paging.size, this.paging.page), 2000);

            ApiService.postWithData(
              url + "invoice/confirm?invoiceId=" + this.targetKonfirmasi.id,
              conjson
            )
              .then((res3) => {
                // this.viewInvoices = res3.data;
                // localStorage.setItem("listDetailInv", JSON.stringify(res3.data));
                toast.success("Sukses Bayar Manual");
                // var modal2 = new Modal(document.getElementById("kt_modal_konfirm_manual"));
                // modal2.hide();
                // this.$router.push("/superadmin/history-pembayaran-manual");
                this.resultBayarManual = true;
                this.successBayarManual = true;
                this.isLoading = false;
              })
              .catch((e) => {
                this.isLoading = false;
                this.resultBayarManual = false;
                this.successBayarManual = false;
                const modal1 = new Modal(document.getElementById("kt_modal_2"));
                modal1.hide();
                this.book.metodeName = "Otomatis";
                toast.error("Gagal, " + e.response.data.detail);
              });
          })
          .catch((e) => {
            toast.error("Ukuran file melebihi 2MB");
            this.isLoading = false;
          });
      }
    },
    changeBank(event) {
      this.dataPayment.paymentId = "";
      this.dataPayment.paymentMethod = "";
      this.dataPayment.paymentStatus = "";
      this.dataPayment.vaName = "";
      this.dataPayment.vaBank = "";
      this.dataPayment.vaNumber = "";
      this.checkDataPayment = {
        data: {
          callbacks: [{ coaCode: "" }],
          vaBank: ""
        }
      };

      this.book.metodeName = "Otomatis";
      this.book.sumberdana = "";
      this.balanceCoa = 0;
      this.bankManual = "";
      this.targetKonfirmasi.rekTujuan = "";
      this.book.bankName = "";
      this.getListBank("AUTOMATIC", "")

      var modal = new Modal(document.getElementById("kt_modal_2"));
      // this.bayarPerInvoiceId(b);
      modal.show();
    },
    saveModalKonfirmasi() {
      const toast = useToast();
      ApiService.postWithoutSlug(
        "crmv2/main_invoice/invoice/update_manual_payment/" +
        this.targetKonfirmasi.paymentId +
        "?process=MANUAL&refNumber=" +
        this.targetKonfirmasi.noRef +
        "&pic=" +
        this.targetKonfirmasi.penanggungJawab
      )
        .then((res3) => {
          // this.viewInvoices = res3.data;
          // localStorage.setItem("listDetailInv", JSON.stringify(res3.data));
          toast.success("Sukses Bayar Manual");
          var modal2 = new Modal(
            document.getElementById("kt_modal_konfirm_manual_fix")
          );
          modal2.hide();

          this.$router.push("/superadmin/history-pembayaran-manual");

          this.isLoading = false;
        })
        .catch((e) => {
          this.isLoading = false;
          toast.error("Gagal Bayar Manual");
        });
      // var modal1 = new Modal(document.getElementById("kt_modal_konfirm_manual"));
      // modal1.hide();
      // var modal2 = new Modal(document.getElementById("kt_modal_konfirm_manual_fix"));
      // modal2.show();
    },
    cancelModalKonfirmasi() {
      var modal = new Modal(
        document.getElementById("kt_modal_konfirm_manual_fix")
      );
      modal.hide();
    },
    bayarManualSingle(e) {
      // this.targetKonfirmasi.paymentId = res3.data.paymentId;
      if (e == "Otomatis") {
        var modal = new Modal(document.getElementById("kt_modal_2"));
        modal.show();
        this.paymentByID();
      } else {
        console.log("paymentId " + this.targetKonfirmasi.paymentId);

        this.tipeBayarManualSingle = true;
        this.buttonBayarManualSingle = false;
        this.errorBayarManual.namaPengirim = "";

        this.errorBayarManual.noRef = "";
        this.errorBayarManual.penanggungJawab = "";

        this.resultBayarManual = false;
        this.targetKonfirmasi.namaPengirim = "";
        this.targetKonfirmasi.noRef = "";
        this.targetKonfirmasi.penanggungJawab = "";
        this.targetKonfirmasi.namaRektujuan = this.returnAcontName(this.checkDataPayment.data.vaBank)
        this.targetKonfirmasi.rekTujuan = this.returnBankName(this.checkDataPayment.data.vaBank)
        var modal2 = new Modal(document.getElementById("kt_modal_2"));
        modal2.hide();
        const modal = new Modal(
          document.getElementById("kt_modal_konfirm_manual")
        );
        modal.show();
      }
    },
    cekInvoiceById(id) {

      var toast = useToast()
      ApiService.getWithoutSlug(`crmv2/main_invoice/invoice/psp/invoice/${id}`).then(resp => {
        console.log(resp.data)
        var modal = new Modal(document.getElementById("kt_modal_preview"));
        modal.show();
      }).catch(err => {
        toast.error(err.response.data.data)
      })
    },
    paymentByID() {
      var metode = this.book.metodeName;
      const toast = useToast();
      this.book.metodeName = metode;

      // if(this.balanceCoa >= this.totalAmount){
      //   return toast.error("Saldo tidak cukup untuk pembayaran invoice");
      // }
      if (this.doSubmit) {
        console.log(this.sumber, "yyyyyyyyyyyyyyy");
        if (this.book.sumberdana == "") {
          this.error2.selectBank2 = "Sumber Dana Harus Diisi";
        }
        if (this.book.bankName == "") {
          this.error1.selectBank1 = "Bank Harus Diisi";
        }
        if (this.sumber == "Topup Manual") {

          if (this.book.bankName == "") {
            return toast.error("Form Kosong wajib diisi")
          }
        }
        // if(this.book.sumberdana == ""){
        //   return toast.error("Form Kosong wajib diisi")
        // }
        // if(this.book.bankName == ""){
        //   return toast.error("Form Kosong wajib diisi")
        // }

        if (this.sumber == "Topup Manual") {
          if (this.balanceCoa < this.totalAmount && !this.showFormCoa) {
            var message =
              "Saldo Coa " +
              this.book.sumberdana +
              " tidak cukup untuk pembayaran invoice";
            this.book.sumberdana = "";
            return toast.error(message);
          }
        }
        if (this.showFormCoa === true) {
          this.book.sumberdana = "";
        }
        //    if(this.balanceCoa <= this.totalAmount){
        //   return toast.error("Saldo tidak cukup untuk pembayaran invoice");
        // }
        // if (this.book.sumberdana == "") {
        //   this.error2.selectBank2 = "Sumber Dana Harus Diisi";
        // }

        if (this.book.bankName == "") {
          this.error1.selectBank1 = "Bank Harus Diisi";
        } else {
          const router = useRouter();
          const route = useRoute();
          this.isLoading = true;
          const metod = metode == "Otomatis" ? "AUTOMATIC" : "MANUAL";
          var url =
            "crmv2/main_invoice/invoice/psp/invoice/pay?bankName=" +
            this.book.bankName +
            "&invoiceId=" +
            this.Idinvoice +
            "&paymentMethod=" +
            metod +
            "&coaCode=" +
            this.book.sumberdana;


          ApiService.postWithoutSlug(url)
            .then((res) => {
              // const modal = new Modal(
              //   document.getElementById("kt_modal_preview")
              // );
              // modal.show();
              // const modal1 = new Modal(
              //   document.getElementById("kt_modal_2")
              // );
              // modal1.hide();



              this.previewPembayaranSingle = false;
              this.dataPayment.paymentMethod = res.data.paymentMethod;
              this.dataPayment.paymentStatus = res.data.paymentStatus;
              this.dataPayment.vaName = res.data.vaName;
              this.dataPayment.vaBank = res.data.vaBank;
              this.dataPayment.vaNumber = res.data.vaNumber;
              this.dataPayment.showExpVaNumber = res.data.showExpVaNumber;
              this.showExpVaNumber = res.data.expiredDate;


              var urlrole_cekpayment_ = "crmv2/main_invoice/invoice/psp/invoice"

              ApiService.getWithoutSlug(
                `${urlrole_cekpayment_}/payment_data/${this.Idinvoice}`
              ).then(
                // ApiService.getWithoutSlug(`${urlrole2}${e}`).then(
                (res2) => {
                  this.checkDataPayment = res2.data;
                }
              );
              this.book.preview = res.data;

              if (metod == "MANUAL") {
                this.statusmodalbayarsingleva = true;
                this.showVaName = res.data.vaName;
                this.showVaBank = res.data.vaBank;
                // this.targetKonfirmasi.namaRektujuan = res.data.vaBank;
                // this.targetKonfirmasi.rekTujuan = res.data.vaNumber;
                this.targetKonfirmasi.paidAmount = res.data.totalAmount;
                this.targetKonfirmasi.persetujuanFinance =
                  res.data.paymentStatus;
                this.targetKonfirmasi.paymentId = res.data.paymentId;
                this.showVaNumber = res.data.vaNumber;
                this.showExpVaNumber = res.data.expiredDate;
                console.log("man");
                this.isLoading = false;
                this.error1.selectBank1 = "";
                this.error2.selectBank2 = "";
              } else {
                this.isLoading = false;
                this.error1.selectBank1 = "";
                this.error2.selectBank2 = "";
                toast.success(this.alertChange);
                // setTimeout(
                //   function (scope) {
                //     location.reload();
                //   },
                //   3000,
                //   this
                // );
                // this.$router.push("/superadmin/invoices");
                // this.batalFormKonfirmasi()
              }
            })
            .catch((e) => {
              this.isLoading = false;
              toast.error("Kode Coa kosong, pembayaran dibatalkan");
            });
        }
      }
    },
    getInvoicesById() {
      const toast = useToast();

      this.isLoading = true;

      ApiService.getWithoutSlug(
        "crmv2/main_invoice/invoice/invoice/" + this.Idinvoice
      )
        .then((response) => {
          this.InvoicesById = response.data;

          this.isLoading = false;
        })
        .catch(({ response }) => {
          toast.error(response.data.detail);

          this.isLoading = false;
        });
    },

    getcombocoa(e) {
      console.log(e, "cuakss")
      ApiService.getWithoutSlug(
        "crmv2/main_invoice/invoice/psp/invoice/coa/combo_coa" +
        "/" +
        e
      ).then(({ data }) => {
        console.log(data, "asasaas");
        this.listcoa = data;
      });
    },

    getbalance(e) {

      const toast = useToast();
      ApiService.getWithoutSlug(
        "crmv2/main_invoice/invoice/psp/invoice/coa/balance" +
        "/" +
        this.schoolid2 +
        "?coaCode=" +
        e
      )
        .then(({ data }) => {

          if (data.balance < this.totalAmount && !this.showFormCoa) {
            var message =
              "Saldo Coa " +
              this.book.sumberdana +
              " tidak cukup untuk pembayaran invoice";
            this.book.sumberdana = "";
            this.balanceCoa = 0;
            return toast.error(message);
          }
          this.balanceCoa = data.balance;
        })

        .catch((error) => { });
      // then(({ data }) => {
      //   console.log(data, "asasaas");
      //   this.listcoa = data;
      // });
    },
    bayarPerInvoiceId(item) {
      this.book.bankName = "";
      this.previewPembayaranSingle = false;
      if (item.paymentStatus == "UNPAID") {
        if (item.vaNumber) {
          this.statusmodalbayarsinglepaid = false;
          if (this.getDateNow() <= item.expiredDate) {
            this.statusmodalbayarsingleva = true;
            this.book.metodeName = "Otomatis";
            this.showVaName = item.vaName;
            this.showVaBank = item.vaBank;
            this.book.bankName = item.vaBank;
            // this.targetKonfirmasi.namaRektujuan = item.vaBank;
            // this.targetKonfirmasi.rekTujuan = item.vaNumber;
            this.targetKonfirmasi.paidAmount = item.totalAmount;
            this.showVaNumber = item.vaNumber;
            this.showExpVaNumber = item.expiredDate;
          } else {
            this.statusmodalbayarsingleva = false;
          }
        } else {
          this.statusmodalbayarsinglepaid = false;
          this.statusmodalbayarsingleva = false;
        }
      } else {
        this.statusmodalbayarsingleva = false;
        this.statusmodalbayarsinglepaid = true;
      }
      this.Idinvoice = item._id;
      this.targetKonfirmasi.id = item._id;
      this.targetKonfirmasi.paymentId = item.paymentId;
    },
    clickReset() {


      (this.book.invoice = []),
        (this.book.bankName = ""),
        (this.book.sumberdana = ""),
        (this.error1.selectBank1 = ""),
        (this.error2.selectBank2 = ""),
        (this.totalAmount = 0),
        // (this.book.metodeName = "Otomatis"),
        (this.book.nomorRekening = ""),
        (this.bankManual = ""),
        (this.alertChange = "Metode Pembayaran Berhasil Dipilih"),
        (this.paymentStatusInit = false),
        (this.balanceCoa = 0),
        // (this.targetKonfirmasi.id = ""),
        // (this.targetKonfirmasi.namaRektujuan = ""),
        // (this.targetKonfirmasi.rekTujuan = "")

        this.dataPayment.item = "";
      this.checkDataPayment = {
        data: {
          callbacks: [{ coaCode: "" }],
          vaBank: ""
        }
      };

      this.book.metodeName = "Otomatis";
      this.book.sumberdana = "";
      this.balanceCoa = 0;
      this.bankManual = "";
      this.showFormCoa = false;
      this.targetKonfirmasi.rekTujuan = "";
      this.book.bankName = "";
      (this.targetKonfirmasi = {
        id: "",
        namaPengirim: "",
        rekTujuan: "",
        persetujuanFinance: "",
        rekPengirim: "",
        tanggal: "",
        note: "",
        paymentId: "",
        noRef: "",
        penanggungJawab: "",
        namaRektujuan: "",
        paidAmount: 0,
        imageBuktiTF: "",
        imageBuktiTFRslt: "",
      });
    },
    // printDownload() {
    //   let w = window.open()
    //     w.document.write(this.$refs.DownloadComp.$el.innerHTML)
    //     w.document.close()
    //     w.setTimeout(function () {
    //     w.print()
    //   }, 1000)
    // },
    cekNull(value) {
      if (value) return value;
      else return "-";
    },
    getDateNow() {
      var today = new Date();
      var dd = String(today.getDate()).padStart(2, "0");
      var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
      var yyyy = today.getFullYear();

      var rslt = yyyy + "-" + mm + "-" + dd;
      return rslt;
    },
  },
});

// function html2pdf(_element: HTMLElement|null) {
//   throw new Error("Function not implemented.");
// }
